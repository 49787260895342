import React, { useContext } from "react";
import formatCurrency from "../../../helpers/formatCurrency";
import { OrderContext } from "../../../stores/OrderProvider";
import { VenueContext } from "../../../stores/VenueProvider";
import { Link } from "react-router-dom";
import { t } from "../../../i18n";
import LinesEllipsis from "react-lines-ellipsis";

export default (props) => {
  const { categories, menuId } = props;
  const orderStore = useContext(OrderContext);
  const venueStore = useContext(VenueContext);
  // const thumbnail = (source) => {
  //   //https://res.cloudinary.com/appero/image/upload/c_scale,w_85/v1594475699/bao%20100ko.jpg
  //   return source.replace('http', 'https').replace('upload/', 'upload/c_scale,w_60/')
  // }

  const getPriceLabel = (product) => {
    var min = 0,
      max = 0;
    min = product.net_price;
    max = product.net_price;
    if (product && product.type_id === 2) {
      product.bundle_categories.forEach((bc) => {
        min += Math.min.apply(
          Math,
          bc.products.map((p) => {
            return p.net_price;
          })
        );
        max += Math.max.apply(
          Math,
          bc.products.map((p) => {
            return p.net_price;
          })
        );
      });
    } else if (product && product.type_id === 3) {
      return (
        <div style={{ display: "flex", flexDirection: "row" }}>
          {
            product.bundle_categories[0].products.map((p, i) =>
              p.highlight ?
                <div key={p.id} style={{ color: p.highlight_color, display: "flex", flexDirection: "row" }}>
                  {formatCurrency(p.net_price, venueStore.venue.currency)}
                  <div style={{ color: 'black', margin: '0px 5px' }}>{i < product.bundle_categories[0].products.length - 1 && "/"}</div>
                </div>
                :
                <div key={p.id} style={{ display: "flex", flexDirection: "row" }}>
                  {formatCurrency(p.net_price, venueStore.venue.currency)}
                  <div style={{ color: 'black', margin: '0px 5px' }}>{i < product.bundle_categories[0].products.length - 1 && "/"}</div>
                </div>)
          }
        </div>
      )
    }

    if (min === max) {
      if (product.highlight) {
        return (
          <div style={{ display: "flex", flexDirection: "row" }}>
            {formatCurrency(min, venueStore.venue.currency)}
            <div style={{ marginLeft: "5px" }}>{product.highlight_text}</div>
          </div>
        );
      } else {
        return formatCurrency(min, venueStore.venue.currency);
      }
    } else {
      if (product.highlight) {
        return (
          <div style={{ display: "flex", flexDirection: "row" }}>
            {formatCurrency(min, venueStore.venue.currency)}
            <div style={{ marginLeft: "5px" }}>{product.highlight_text}</div>
          </div>
        );
      } else {
        return (
          <div style={{ display: "flex", flexDirection: "row" }}>
            {formatCurrency(min, venueStore.venue.currency)}
          </div>
        );
      }
    }
  };

  const expiringStorage = require("../../../helpers/expiringStorage");

  return (
    <>
      <div id='products-container' className='products-container cst-products-container cst-global-products-container '>
        {categories.map((c) => (
          <div className='product-category' key={c.id}>
            <div
              id={c.id}
              className={`product-category-name cst-_category-name cst-global-_category-name cst-menu-${menuId} cst-item-${c.id} `}
              data-cst='cst'
              style={{ position: "relative" }}
            >
              {c.name}
            </div>
            {!!c.tagline && (
              <div
                className={`product-category-tagline cst-_category-tagline cst-menu-${menuId} cst-item-${c.id} `}
                data-cst='cst'
                style={{ position: "relative" }}
              >
                {c.tagline}
              </div>
            )}
            {(c.products.flat().filter((p) => p.standalone === true && p.unavailable === false) || [])
              .sort((a, b) => a.position - b.position)
              .map((product) => (
                <Link
                  to={`/product/${product.id}`}
                  key={product.id}
                  style={{ textDecoration: "none", color: "inherit" }}
                  onClick={() => {
                    expiringStorage.set("offset", document.getElementById(product.id).offsetTop, 60000);
                    window.amplitude.getInstance().logEvent("PRODUCT_VIEW", {
                      uuid: product.id,
                      name: product.name,
                      venue_uuid: venueStore.venue.id,
                      venue_name: venueStore.venue.name,
                    })
                  }}
                >
                  <div
                    id={product.id}
                    className={
                      `product-card cst-product-card cst-global-product-card cst-menu-${menuId} cst-category-${c.id} cst-item-${product.id} ` +
                      (orderStore.order.products[product.id] != null && orderStore.order.products[product.id].quantity > 0 ? "added" : "")
                    }
                    style={{ position: "relative" }}
                    data-cst='cst'
                    key={product.id}
                  >
                    <div
                      className={`product cst-product cst-menu-${menuId} cst-category-${c.id} cst-item-${product.id} ${
                        product.unavailable ? "unavailable" : ""
                      }`}
                      data-cst='cst'
                    >
                      <div className='product-description'>
                        <div className='product-quantity-name cst-product-quantity-name cst-global-product-quantity-name'>
                          {orderStore.order.products[product.id] && orderStore.order.products[product.id].quantity > 0 && (
                            <div className='quantity'>{orderStore.order.products[product.id].quantity + "x"}</div>
                          )}
                          <div className={`name cst-name cst-menu-${menuId} cst-category-${c.id} cst-item-${product.id} `} data-cst='cst'>
                            {product.localized_name || product.name}
                          </div>
                        </div>
                        {product.recipe ? (
                          <div style={{ position: "relative" }}>
                            <LinesEllipsis
                              className={` recipe cst-recipe cst-global-recipe cst-menu-${menuId} cst-category-${c.id} cst-item-${product.id}`}
                              text={product.recipe}
                              maxLine='2'
                              ellipsis='...'
                              trimRight
                              basedOn='words'
                              data-cst='cst'
                            />
                          </div>
                        ) : (
                          <div className={`product cst-product cst-menu-${menuId} cst-category-${c.id} cst-item-${product.id}`} style={{ height: "24px" }} />
                        )}

                        <div
                          className={`price price cst-price cst-global-price cst-menu-${menuId} cst-category-${c.id} cst-item-${product.id}`}
                          style={{ color: product.highlight_color || "", position: "relative" }}
                          data-cst='cst'
                        >
                          {getPriceLabel(product)}
                        </div>
                        {/* <div className={`tagbox cst-tagbox cst-global-tagbox`}>
                          {venueStore.tags && venueStore.tags.length
                            ? venueStore.tags
                                .filter((tag) => tag.productId.includes(product.id))
                                .map((tag) => (
                                  <div
                                    className={`tag cst-tag cst-menu-${menuId} cst-category-${c.id} cst-item-${tag.id} ${tag.chip ? "chip" : ""}`}
                                    data-cst='cst'
                                  >
                                    {" "}
                                    {tag.text}{" "}
                                  </div>
                                ))
                            : null}
                        </div> */}
                      </div>
                      {// product.image ? <div className="product-image"><img src={thumbnail(product.image)} alt="product" /></div> : null
                      product.image ? (
                        <div className='product-image cst-productList-image'>
                          <img
                            src={product.image}
                            className={`cst-image-product-list cst-menu-${menuId} cst-category-${c.id} cst-item-${product.id}`}
                            style={{ position: "relative" }}
                            data-cst='cst'
                            alt='product'
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>
                </Link>
              ))}
          </div>
        ))}
      </div>
    </>
  );
};
