import React, { useContext, useState } from "react";
import { OrderContext } from "../../../stores/OrderProvider";
import { VenueContext } from "../../../stores/VenueProvider";
import formatCurrency from "../../../helpers/formatCurrency";
import { useHistory, Link } from "react-router-dom";
import LinesEllipsis from "react-lines-ellipsis";
import RadioSelect from "../../../components/RadioSelect";
import { t } from "../../../i18n";
import "../Product.css";

const SubCategory = (props) => {
    const venueStore = useContext(VenueContext)
    const orderStore = useContext(OrderContext)
    const subcategory = props.category
    const products = subcategory.products
    const isMulti = subcategory.multiple_choice
    const onSelect = props.onSelect
    const payment = venueStore.currentMenu.payment
    const [showInstructions, setShowInstructions] = useState({})
    const [selectedInstructions, setSelectedInstructions] = useState({})
    const menuId = venueStore.currentMenu.id || "";

    const onProductPick = (sp) => {
        if (orderStore.products[sp].instructions.length) {
            if (!!!showInstructions[sp]) {
                setShowInstructions({ ...showInstructions, [sp]: true });
            }
        } else {
            onSelect(products.find((p) => p.id === sp));
        }
    };

    const onInstructionPick = (sp, group, i) => {
        let updatedInstructions = { ...selectedInstructions, [group]: i };
        setSelectedInstructions(updatedInstructions);
        onSelect({ ...sp, instructions: Object.entries(updatedInstructions).map(([key, value]) => value) });
    };

    return payment ? (
        <RadioSelect
            name={subcategory.name}
            onChange={(event) => {
                if (typeof products.find((p) => p.id === event.target.value) === "undefined") return;
                onProductPick(event.target.value);
            }}
            options={products.map((sp) => ({
                id: sp.id,
                name: `${sp.name} ${sp.net_price ? `(${formatCurrency(sp.net_price, venueStore.venue.currency)})` : ""}`,
                description: sp.description,
                children:
                    payment && sp.instructions.length && !!showInstructions[sp.id]
                        ? sp.instructions.map((group) => (
                            <RadioSelect
                                key={`radio-${group}`}
                                name={orderStore.instructions.find((e) => e.uuid === group).name}
                                onChange={(event) => onInstructionPick(sp, group, event.target.value)}
                                options={orderStore.instructions
                                    .find((e) => e.uuid === group)
                                    .instructions.sort((a, b) => a.position - b.position)
                                    .map((i) => ({ id: i.id, name: i.name }))}
                                isMulti={false}
                            />
                        ))
                        : null,
            }))}
            isMulti={isMulti}
        />
    ) : (
        <div className='product-fp-bundle-category' key={subcategory.name}>
            <div
                className={`product-fp-bundle-category-name cst-product-fp-bundle-category-name cst-menu-${menuId} cst-item-${subcategory.name}`}
                data-cst='cst'
                style={{ position: "relative" }}
            >
                {subcategory.name}
            </div>
            {products
                .sort((a, b) => a.position - b.position)
                .map((sp) => (
                    <Link
                        to={{
                            pathname: `/product/${sp.id}`,
                            state: {
                                price: sp.net_price,
                            },
                        }}
                        style={{ textDecoration: "none", color: "inherit" }}
                    >
                        <div className='product-fp-bundle-product' key={sp.id}>
                            <div className='product-fp-bundle-product-info'>
                                <div
                                    className={`product-fp-bundle-product-name cst-product-fp-bundle-product-name cst-menu-${menuId} cst-subcategory-${subcategory.name
                                        } cst-item-${sp.id}`}
                                    data-cst='cst'
                                    style={{ position: "relative" }}
                                >
                                    {sp.name}
                                </div>
                                {sp.description ? (
                                    <div className={`product-fp-bundle-product-desc `} style={{ position: "relative" }}>
                                        <LinesEllipsis
                                            text={sp.description}
                                            maxLine='2'
                                            ellipsis='...'
                                            trimRight
                                            basedOn='words'
                                            className={`cst-product-fp-bundle-product-desc cst-menu-${menuId} cst-subcategory-${subcategory.name} cst-item-${sp.id}`}
                                            data-cst='cst'
                                        />
                                    </div>
                                ) : null}
                                {sp.net_price && sp.net_price > 0 ? (
                                    <div
                                        className={`product-fp-bundle-product-price cst-product-fp-bundle-product-price cst-menu-${menuId} cst-subcategory-${subcategory.name
                                            } cst-item-${sp.id}`}
                                        data-cst='cst'
                                        style={{ position: "relative" }}
                                    >
                                        +{formatCurrency(sp.net_price, venueStore.venue.currency)}
                                    </div>
                                ) : null}
                            </div>
                            {sp.image ? <img src={sp.image} height='75px' width='75px' /> : null}
                        </div>
                    </Link>
                ))}
        </div>
    );
};

export default (props) => {
    const history = useHistory();
    const product = props.product;
    const venueStore = useContext(VenueContext);
    const orderStore = useContext(OrderContext);
    const [amount, setAmount] = useState(
        orderStore.order.products[product.id] && orderStore.order.products[product.id].quantity ? orderStore.order.products[product.id].quantity : 1
    );
    const price = product.net_price || 0;
    const hasCost = product.cost_id != null;
    const cost = orderStore.order.costs.filter((c) => c.id === product.cost_id)[0];
    const [costAmount, setCostAmount] = useState(product.costAmount);
    const costPrice = hasCost ? cost.net_price : null;
    const payment = venueStore.currentMenu.payment;
    const listEnabled = venueStore.venue.wishlist;
    const [subProducts, setSubProducts] = useState({});
    const [purchaseEnabled, setPurchaseEnabled] = useState(false);

    const menuId = venueStore.currentMenu.id || "";
    const categoryId = product.category_id || "";

    const getPriceLabel = (product) => {
        var min = 0,
            max = 0;
        min = product.net_price;
        max = product.net_price;
        if (product && product.type_id === 2) {
            product.bundle_categories.forEach((bc) => {
                min += Math.min.apply(
                    Math,
                    bc.products.map((p) => {
                        return p.net_price;
                    })
                );
                max += Math.max.apply(
                    Math,
                    bc.products.map((p) => {
                        return p.net_price;
                    })
                );
            });
        } else if (product && product.type_id === 3) {
            return (
                <div style={{ display: "flex", flexDirection: "row" }}>
                    {
                        product.bundle_categories[0].products.map((p, i) =>
                            p.highlight ?
                                <div key={p.id} style={{ color: p.highlight_color, display: "flex", flexDirection: "row" }}>
                                    {formatCurrency(p.net_price, venueStore.venue.currency)}
                                    <div style={{ color: 'black', margin: '0px 5px' }}>{i < product.bundle_categories[0].products.length - 1 && "/"}</div>
                                </div>
                                :
                                <div key={p.id} style={{ display: "flex", flexDirection: "row" }}>
                                    {formatCurrency(p.net_price, venueStore.venue.currency)}
                                    <div style={{ color: 'black', margin: '0px 5px' }}>{i < product.bundle_categories[0].products.length - 1 && "/"}</div>
                                </div>)
                    }
                </div>
            )
        }

        if (min === max) {
            if (product.highlight) {
                return (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        {formatCurrency(min, venueStore.venue.currency)}
                        <div style={{ marginLeft: "5px" }}>{product.highlight_text}</div>
                    </div>
                );
            } else {
                return formatCurrency(min, venueStore.venue.currency);
            }
        } else {
            if (product.highlight) {
                return (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        {formatCurrency(min, venueStore.venue.currency)}
                        <div style={{ marginLeft: "5px" }}>{product.highlight_text}</div>
                    </div>
                );
            } else {
                return (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        {formatCurrency(min, venueStore.venue.currency)}
                    </div>
                );
            }
        }
    };

    const updateSelection = (category, subproduct) => {
        if (subProducts[category] && subProducts[category].id === subproduct.id) {
            subProducts[category] = null;
        } else {
            subProducts[category] = {
                id: subproduct.id,
                name: subproduct.name,
                net_price: parseFloat(subproduct.net_price),
                instructions: subproduct.instructions,
            };
        }
        setPurchaseEnabled(Object.values(subProducts).length >= product.bundle_categories.length);
    };

    const subProductsPrice = () => {
        var total = 0;
        Object.entries(subProducts).forEach(([k, v]) => {
            total += v.net_price || 0;
        });
        return total;
    };

    const addBundle = () => {
        let pdcts = [];
        Object.values(subProducts).forEach((sp) => {
            //In case multiple choices are enabled we'll get an array of objects, otherwise an object
            if (Array.isArray(sp)) {
                sp.forEach((el) => pdcts.push({ [el.id]: { quantity: 1, name: el.name, net_price: el.net_price, instructions: el.instructions } }));
            } else {
                pdcts.push({ [sp.id]: { quantity: 1, name: sp.name, net_price: sp.net_price, instructions: sp.instructions } });
            }
        });
        orderStore.updateOrder(product.id, pdcts, null, 1, product.net_price + subProductsPrice(), 0);
        setSubProducts({});
        history.goBack();
    };

    return (
        <>
            <div className='product-fp-close' onClick={history.goBack}>
                {" "}
                x
            </div>
            <div className='product-fp-info cst-product-fp-info cst-global-product-fp-info'>
                <div className='product-fp-title' style={{ marginTop: "30px" }}>
                    <div
                        className={`product-fp-name cst-product-fp-name cst-menu-${menuId} cst-category-${categoryId} cst-item-${product.id}`}
                        data-cst='cst'
                        style={{ position: "relative" }}
                    >
                        {product.name}
                    </div>
                    <div
                        className={`product-fp-price cst-product-fp-price cst-${menuId} cst-category-${categoryId} cst-item-${product.id}`}
                        data-cst='cst'
                        style={{ position: "relative" }}
                    >
                        {getPriceLabel(product)}
                    </div>
                </div>
                <div
                    className={`product-fp-desc cst-product-fp-desc cst-menu-${menuId} cst-category-${categoryId} cst-item-${product.id}`}
                    data-cst='cst'
                    style={{ position: "relative" }}
                >
                    {product.recipe}{" "}
                    {/* <div className={`tagbox-product cst-tagbox-product cst-global-tagbox-product`}>
                        {venueStore.tags && venueStore.tags.length
                            ? venueStore.tags
                                .filter((tag) => tag.productId.includes(product.id))
                                .map((tag) => (
                                    <div
                                        className={`tag cst-tag-product cst-menu-${menuId} cst-category-${categoryId} cst-item-${tag.id} ${tag.chip ? "chip" : ""}`}
                                        data-cst='cst'
                                    >
                                        {" "}
                                        {tag.text}{" "}
                                    </div>
                                ))
                            : null}
                    </div> */}
                </div>
                {product.bundle_categories.map((bc) => (
                    <SubCategory key={bc.name} category={bc} onSelect={(product) => updateSelection(bc.name, product)} />
                ))}
                {product.recommendations.length ? (
                    <div className='product-fp-recommended'>
                        <div className={`product-fp-recommended-title cst-product-fp-recommended-title cst-menu-${menuId} cst-category-${categoryId} cst-item-${product.id}`} data-cst='cst' style={{ position: "relative" }}>{t("Products.recommended")}</div>
                        {product.recommendations.map((p) => (
                            <Link
                                to={`/product/${p}`}
                                key={`product-${p}`}
                                style={{ textDecoration: "none", color: "inherit" }}
                                onClick={() => {
                                    window.amplitude.getInstance().logEvent("PRODUCT_VIEW", {
                                        uuid: p,
                                        name: orderStore.products[p].name,
                                        venue_uuid: venueStore.venue.id,
                                        venue_name: venueStore.venue.name,
                                    });
                                }}
                            >
                                <div
                                    className={
                                        orderStore.order.products[p] && orderStore.order.products[p].quantity > 0
                                            ? "product-fp-recommended-product-selected"
                                            : "product-fp-recommended-product"
                                    }
                                >
                                    <div className='product-fp-recommended-product-info'>
                                        <div className='product-fp-recommended-product-name'>
                                            {orderStore.order.products[p] && orderStore.order.products[p].quantity > 0
                                                ? orderStore.order.products[p].quantity + "x " + orderStore.products[p].name
                                                : orderStore.products[p].name}
                                        </div>
                                        <div className='product-fp-recommended-product-desc'>
                                            <LinesEllipsis
                                                text={orderStore.products[p].recipe || ''}
                                                maxLine='2'
                                                ellipsis='...'
                                                trimRight
                                                basedOn='words'
                                            />
                                        </div>
                                        <div className='price' style={{ color: orderStore.products[p].highlight_color || "" }}>
                                            {getPriceLabel(orderStore.products[p])}
                                        </div>
                                    </div>
                                    {orderStore.products[p].image ? <img src={orderStore.products[p].image} height='75px' width='75px' /> : null}
                                </div>
                            </Link>
                        ))}
                    </div>
                ) : null}
                <div className='product-fp-inset' />
            </div>
            {payment ? (
                <>
                    <div className='product-fp-footer'>
                        <button className='product-fp-footer-button' onClick={addBundle} disabled={!purchaseEnabled}>
                            <div className='product-fp-button-left'>{t("Menu.go")}</div>
                            <div className='product-fp-button-right'>
                                {formatCurrency(price + subProductsPrice() + costAmount * costPrice, venueStore.venue.currency)}
                            </div>
                        </button>
                    </div>
                    {
                        payment || listEnabled ?
                            <div className="product-fp-footer">
                                <button className="product-fp-footer-button" onClick={addBundle} disabled={!purchaseEnabled}>
                                    <div className="product-fp-button-left">{t("Menu.go")}</div>
                                    <div className="product-fp-button-right">{formatCurrency(price + subProductsPrice() + costAmount * costPrice, venueStore.venue.currency)}</div>
                                </button>
                            </div>
                            :
                            null
                    }
                </>
            ) : null}
        </>
    );
};
