import React, { useEffect, useState } from "react";
import "./App.css";
import logo from "./images/logo.png";
import Venue from "./pages/Venue/Venue";
import Menu from "./pages/Menu/Menu";
import Product from "./pages/Product/Product";
import Order from "./pages/Order/Order";
import Payment from "./pages/Payment/Payment";
import Confirmation from "./pages/Confirmation/Confirmation";
import Summary from "./pages/Summary/Summary";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import VenueProvider from "./stores/VenueProvider";
import OrderProvider from "./stores/OrderProvider";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import * as API from "./api/api.js";
import smoothscroll from "smoothscroll-polyfill";

import { styleSelector } from "./components/styles/styleSelector";
import { fetchCustomStylesheet } from "./components/styles/fetchCustomStylesheet";

const stripe = new Promise((resolve) => {
  API.getStripeKey(window.location.hash.slice(1).split("/")[0]).then((result) => resolve(loadStripe(result.key)));
});

export default () => {
  const venueId = window.location.hash.slice(1).split("/")[0];

  const ELEMENTS_OPTIONS = {
    fonts: [
      {
        cssSrc: "https://fonts.googleapis.com/css?family=Muli&display=swap",
      },
    ],
  };

  smoothscroll.polyfill();

  styleSelector();

  useEffect(() => {
    loadCustomStyles();
  }, [venueId]);

  const loadCustomStyles = async () => {
    fetchCustomStylesheet(venueId);
  };
  

  return (
    <div className='main-container cst-main-container cst-global-main-container' key={venueId}>
      <div className='logo-container'>
        <a href='http://appero.co'>
          <img src={logo} alt='logo' />
        </a>
      </div>
      <VenueProvider>
        <OrderProvider>
          <Elements stripe={stripe} options={ELEMENTS_OPTIONS}>
            <Router basename={venueId} hashType='noslash'>
              <Switch>
                <Route exact path='/' component={Venue} />
                <Route exact path='/table/:table' component={Venue} />
                <Route path='/menu' component={Menu} />
                <Route path='/product/:id' render={(routeProps) => <Product {...routeProps} key={document.location.href} />} />
                <Route path='/order' component={Order} />
                <Route path='/payment' component={Payment} />
                <Route path='/confirmation' component={Confirmation} />
                <Route path='/summary' component={Summary} />
              </Switch>
            </Router>
          </Elements>
        </OrderProvider>
      </VenueProvider>
    </div>
  );
};
