import React, { useContext, useState } from "react";
import { OrderContext } from "../../../stores/OrderProvider";
import { VenueContext } from "../../../stores/VenueProvider";
import formatCurrency from "../../../helpers/formatCurrency";
import { useHistory, Link, useLocation } from "react-router-dom";
import LinesEllipsis from "react-lines-ellipsis";
import RadioSelect from "../../../components/RadioSelect";
import Cost from "./Cost";
import { t } from "../../../i18n";
import "../Product.css";

export default (props) => {
  const history = useHistory();
  const location = useLocation();
  const product = props.product;
  const venueStore = useContext(VenueContext);
  const orderStore = useContext(OrderContext);
  const [amount, setAmount] = useState(
    orderStore.order.products[product.id] && orderStore.order.products[product.id].quantity ? orderStore.order.products[product.id].quantity : 1
  );
  const price = product.net_price || 0;
  const hasPrice = price && price > 0;
  const hasCost = product.cost_id != null && orderStore.order.costs.filter((c) => c.id === product.cost_id).length > 0;
  const cost = hasCost ? orderStore.order.costs.filter((c) => c.id === product.cost_id)[0] : null;
  const [costAmount, setCostAmount] = useState(product.costAmount);
  const costPrice = hasCost ? cost.net_price : null;
  const payment = venueStore.currentMenu.payment;
  const listEnabled = venueStore.venue.wishlist;
  const [selectedInstructions, setSelectedInstructions] = useState({});
  const categoryId = product.category_id || "";
  const menuId = venueStore.currentMenu.id || "";

  const getPriceLabel = (product) => {
    var min = 0,
      max = 0;
    min = product.net_price;
    max = product.net_price;
    if (product && product.type_id === 2) {
      product.bundle_categories.forEach((bc) => {
        min += Math.min.apply(
          Math,
          bc.products.map((p) => {
            return p.net_price;
          })
        );
        max += Math.max.apply(
          Math,
          bc.products.map((p) => {
            return p.net_price;
          })
        );
      });
    } else if (product && product.type_id === 3) {
      min += product.bundle_categories[0].products.reduce(
        (min, p) => (p.net_price < min ? p.net_price : min),
        product.bundle_categories[0].products[0].net_price
      );
      max += product.bundle_categories[0].products.reduce(
        (max, p) => (p.net_price > max ? p.net_price : max),
        product.bundle_categories[0].products[0].net_price
      );
    }
    if (min === max) {
      if (!!product.highlight) {
        return (
          <div style={{ display: "flex", flexDirection: "row", color: product.highlight_color || "" }}>
            {formatCurrency(min, venueStore.venue.currency)}
          </div>
        );
      } else {
        return (
          <div style={{ display: "flex", flexDirection: "row", color: "rgba(40, 63, 100, 1)" }}>
            {formatCurrency(min, venueStore.venue.currency)}
          </div>
        );
      }
    } else {
      if (!!product.highlight) {
        return (
          <div style={{ display: "flex", flexDirection: "row", color: product.highlight_color || ""  }}>
            {formatCurrency(min, venueStore.venue.currency)}
          </div>
        );
      } else {
        return (
          <div style={{ display: "flex", flexDirection: "row", color: "rgba(40, 63, 100, 1)" }}>
            {formatCurrency(min, venueStore.venue.currency)}
          </div>
        );
      }
    }
  };

  return (
    <>
      <div className='product-fp-close' onClick={history.goBack}>
        {" "}
        x
      </div>
      <div className='product-fp-info cst-product-fp-info cst-global-product-fp-info'>
        <div className='product-fp-title'>
          <div
            className={`product-fp-name cst-product-fp-name cst-menu-${menuId} cst-category-${categoryId} cst-item-${product.id}  `}
            style={{ position: "relative" }}
            data-cst='cst'
          >
            {product.name}
          </div>
          {hasPrice ? (
            <div
              className={`product-fp-price cst-product-fp-price cst-menu-${menuId} cst-category-${categoryId} cst-item-${product.id}`}
              style={{ position: "relative" }}
              data-cst='cst'
            >
              {getPriceLabel(product)}
            </div>
          ) : null}
        </div>
        <div
          className={`product-fp-desc cst-product-fp-desc cst-menu-${menuId} cst-category-${categoryId} cst-item-${product.id}`}
          style={{ position: "relative" }}
          data-cst='cst'
        >
          {product.recipe}{" "}
          {/* <div className={`tagbox-product cst-tagbox-product cst-global-tagbox-product`}>
            {venueStore.tags && venueStore.tags.length
              ? venueStore.tags
                  .filter((tag) => tag.productId.includes(product.id))
                  .map((tag) => (
                    <div
                      className={`tag cst-tag-product cst-menu-${menuId} cst-category-${categoryId} cst-item-${tag.id} ${tag.chip ? "chip" : ""}`}
                      data-cst='cst'
                    >
                      {" "}
                      {tag.text}{" "}
                    </div>
                  ))
              : null}
          </div> */}
        </div>
        {payment && product.instructions && product.instructions.length
          ? product.instructions.map((group) => (
              <RadioSelect
                key={group}
                name={orderStore.instructions.find((e) => e.uuid === group).name}
                onChange={(event) => setSelectedInstructions({ ...selectedInstructions, [group]: event.target.value })}
                options={orderStore.instructions
                  .find((e) => e.uuid === group)
                  .instructions.sort((a, b) => a.position - b.position)
                  .map((i) => ({ id: i.id, name: i.name }))}
              />
            ))
          : null}
        {payment || listEnabled ? (
          <div className='product-fp-purchase'>
            <div className='product-fp-amount-update' onClick={() => setAmount(amount > 1 ? amount - 1 : 1)}>
              -
            </div>
            <div className='product-fp-amount-value'>{amount}</div>
            <div className='product-fp-amount-update' onClick={() => setAmount(amount + 1)}>
              +
            </div>
          </div>
        ) : null}
        {payment && hasCost ? <Cost product={product} onChange={(amount) => setCostAmount(amount)} /> : null}
        {product.recommendations.length ? (
          <div className='product-fp-recommended'>
            <div
              className={`product-fp-recommended-title cst-product-fp-recommended-title cst-menu-${menuId} cst-category-${categoryId} cst-item-${
                product.id
              }`}
              data-cst='cst'
              style={{ position: "relative" }}
            >
              {t("Products.recommended")}
            </div>
            {product.recommendations.map((p) => (
              <Link
                to={`/product/${p}`}
                key={`product-${p}`}
                style={{ textDecoration: "none", color: "inherit" }}
                onClick={() => {
                  window.amplitude.getInstance().logEvent("PRODUCT_VIEW", {
                    uuid: p,
                    name: orderStore.products[p].name,
                    venue_uuid: venueStore.venue.id,
                    venue_name: venueStore.venue.name,
                  });
                }}
              >
                <div
                  className={
                    orderStore.order.products[p] && orderStore.order.products[p].quantity > 0
                      ? "product-fp-recommended-product-selected"
                      : "product-fp-recommended-product"
                  }
                >
                  <div className='product-fp-recommended-product-info'>
                    <div className='product-fp-recommended-product-name'>
                      {orderStore.order.products[p] && orderStore.order.products[p].quantity > 0
                        ? orderStore.order.products[p].quantity + "x " + orderStore.products[p].name
                        : orderStore.products[p].name}
                    </div>
                    <div className='product-fp-recommended-product-desc'>
                      <LinesEllipsis
                        text={orderStore.products[p].recipe || ''}
                        maxLine='2'
                        ellipsis='...'
                        trimRight
                        basedOn='words'
                      />
                    </div>
                    <div className='price' style={{ color: product.highlight_color || "" }}>
                      {getPriceLabel(orderStore.products[p])}
                    </div>
                  </div>
                  {orderStore.products[p].image ? <img src={orderStore.products[p].image} height='75px' width='75px' /> : null}
                </div>
              </Link>
            ))}
          </div>
        ) : null}
        <div className='product-fp-inset' />
      </div>
      {payment || listEnabled ? (
        <div className='product-fp-footer'>
          <button
            className='product-fp-footer-button'
            onClick={() => {
              orderStore.updateOrder(product.id, null, selectedInstructions, amount, product.net_price, costAmount);
              history.goBack();
            }}
          >
            <div className='product-fp-button-left'>{t("Menu.go")}</div>
            <div className='product-fp-button-right'>{formatCurrency(amount * price + costAmount * costPrice, venueStore.venue.currency)}</div>
          </button>
        </div>
      ) : null}
    </>
  );
};
