import React, { useContext, useState } from "react";
import { OrderContext } from "../../../stores/OrderProvider";
import { VenueContext } from "../../../stores/VenueProvider";
import formatCurrency from "../../../helpers/formatCurrency";
import { useHistory, Link } from "react-router-dom";
import LinesEllipsis from "react-lines-ellipsis";
import Cost from "./Cost";
import { t } from "../../../i18n";
import "../Product.css";

export default (props) => {
  const history = useHistory();
  const product = props.product;
  const venueStore = useContext(VenueContext);
  const orderStore = useContext(OrderContext);
  const price = (p) => p.net_price || 0;
  const hasCost = product.cost_id != null;
  const cost = orderStore.order.costs.filter((c) => c.id === product.cost_id)[0];
  const [costAmount, setCostAmount] = useState(product.costAmount);
  const payment = venueStore.currentMenu.payment;
  const listEnabled = venueStore.venue.wishlist;
  let items = {};
  product.bundle_categories[0].products.forEach(
    (p) => (items[p.id] = orderStore.order.products[p.id] ? orderStore.order.products[p.id].quantity || 0 : 0)
  );
  const [selection, setSelection] = useState(items);

  const menuId = venueStore.currentMenu.id || "";
  const categoryId = product.category_id || "";

  const total = () => {
    var sum = 0;
    for (var id in selection) {
      let p = product.bundle_categories[0].products.filter((p) => p.id === id)[0];
      let price = p.net_price;
      sum += selection[id] * price;
    }
    return sum;
  };
  const addSelection = async () => {
    for (let id in selection) {
      let p = product.bundle_categories[0].products.filter((p) => p.id === id)[0];
      orderStore.updateOrder(id, null, null, selection[id], p.net_price, 0);
      await new Promise((r) => setTimeout(r, 10));
    }
    history.goBack();
  };

  const getPriceLabel = (product) => {
    var min = 0,
      max = 0;
    min = product.net_price;
    max = product.net_price;
    if (product && product.type_id === 2) {
      product.bundle_categories.forEach((bc) => {
        min += Math.min.apply(
          Math,
          bc.products.map((p) => {
            return p.net_price;
          })
        );
        max += Math.max.apply(
          Math,
          bc.products.map((p) => {
            return p.net_price;
          })
        );
      });
    } else if (product && product.type_id === 3) {
      min += product.bundle_categories[0].products.reduce(
        (min, p) => (p.net_price < min ? p.net_price : min),
        product.bundle_categories[0].products[0].net_price
      );
      max += product.bundle_categories[0].products.reduce(
        (max, p) => (p.net_price > max ? p.net_price : max),
        product.bundle_categories[0].products[0].net_price
      );
    }

    if (min === max) {
      if (product.highlight) {
        return (
          <div style={{ display: "flex", flexDirection: "row" }}>
            {formatCurrency(min, venueStore.venue.currency)}
            <div style={{ marginLeft: "5px" }}>{product.highlight_text}</div>
          </div>
        );
      } else {
        return formatCurrency(min, venueStore.venue.currency);
      }
    } else {
      if (product.highlight) {
        return (
          <div style={{ display: "flex", flexDirection: "row" }}>
            {formatCurrency(min, venueStore.venue.currency)}
            <div style={{ marginLeft: "5px" }}>{product.highlight_text}</div>
          </div>
        );
      } else {
        return (
          <div style={{ display: "flex", flexDirection: "row" }}>
            {formatCurrency(min, venueStore.venue.currency)}
          </div>
        );
      }
    }
  };

  return (
    <>
      <div className='product-fp-close' onClick={history.goBack}>
        {" "}
        x
      </div>
      <div className='product-fp-info cst-product-fp-info cst-global-product-fp-info'>
        <div className='product-fp-title' style={{ marginTop: "30px" }}>
          <div className={`product-fp-name cst-product-fp-name cst-menu-${menuId} cst-category-${categoryId} cst-item-${product.id}`} data-cst='cst'>
            {product.name}
          </div>
        </div>
        <div className={`product-fp-desc cst-product-fp-desc cst-menu-${menuId} cst-category-${categoryId} cst-item-${product.id}`} data-cst='cst'>
          {product.recipe}{" "}
          {/* <div className={`tagbox-product cst-tagbox-product cst-global-tagbox-product`}>
            {venueStore.tags && venueStore.tags.length
              ? venueStore.tags
                  .filter((tag) => tag.productId.includes(product.id))
                  .map((tag) => (
                    <div
                      className={`tag cst-tag-product cst-menu-${menuId} cst-category-${categoryId} cst-item-${tag.id} ${tag.chip ? "chip" : ""}`}
                      data-cst='cst'
                    >
                      {" "}
                      {tag.text}{" "}
                    </div>
                  ))
              : null}
          </div> */}
        </div>
        {product.bundle_categories[0].products.map((p) => (
          <div className='product-fp-subproduct' key={p.id}>
            <Link
              to={`/product/${p.id}`}
              style={{ textDecoration: "none", color: "inherit" }}
              onClick={(event) => {
                window.amplitude.getInstance().logEvent("PRODUCT_VIEW", {
                  uuid: p.id,
                  name: p.name,
                  venue_uuid: venueStore.venue.id,
                  venue_name: venueStore.venue.name,
                });
                if (event.target === document.getElementById("plus" + p.id) || event.target === document.getElementById("minus" + p.id)) {
                  event.preventDefault();
                }
              }}
            >
              <div className='product-fp-subproduct-card'>
                <div className='product-fp-subproduct-info'>
                  <div
                    className={`product-fp-subproduct-name cst-product-fp-subproduct-name cst-menu-${menuId} cst-category-${categoryId} cst-item-${
                      p.id
                    }`}
                    style={{ position: "relative" }}
                    data-cst='cst'
                  >
                    {selection[p.id] > 0 ? selection[p.id] + "x " + p.name : p.name}
                  </div>
                  {p.description ? (
                    <div style={{ position: "relative" }}>
                      <LinesEllipsis
                        text={p.description}
                        maxLine='2'
                        ellipsis='...'
                        trimRight
                        basedOn='words'
                        className={`product-fp-subproduct-desc cst-product-fp-subproduct-desc cst-menu-${menuId} cst-category-${categoryId} cst-item-${
                          p.id
                        }`}
                        data-cst='cst'
                      />
                    </div>
                  ) : null}
                  <div
                    className={`product-fp-subproduct-price cst-product-fp-subproduct-price cst-menu-${menuId} cst-category-${categoryId} cst-item-${
                      p.id
                    }`}
                    data-cst='cst'
                    style={{ color: p.highlight_color || "", position: "relative" }}
                  >
                    {getPriceLabel(p)}
                  </div>
                  {payment || listEnabled ? (
                    <div className='product-fp-subproduct-purchase'>
                      <div
                        id={"minus" + p.id}
                        className='product-fp-subproduct-amount-update'
                        onClick={() => setSelection({ ...selection, [p.id]: selection[p.id] > 1 ? selection[p.id] - 1 : 0 })}
                      >
                        -
                      </div>
                      <div
                        id={"plus" + p.id}
                        className='product-fp-subproduct-amount-update'
                        onClick={() => setSelection({ ...selection, [p.id]: selection[p.id] + 1 })}
                      >
                        +
                      </div>
                    </div>
                  ) : null}
                </div>
                {p.image ? (
                  <div className='product-fp-subproduct-image'>
                    <img src={p.image} alt='' height={75} width={75} />
                  </div>
                ) : null}
              </div>
            </Link>
          </div>
        ))}
        {payment && hasCost ? <Cost product={product} onChange={(amount) => setCostAmount(amount)} /> : null}
        {product.recommendations.length ? (
          <div className='product-fp-recommended'>
            <div
              className={`product-fp-recommended-title cst-product-fp-recommended-title cst-menu-${menuId} cst-category-${categoryId} cst-item-${
                product.id
              }`}
              data-cst='cst'
              style={{ position: "relative" }}
            >
              {t("Products.recommended")}
            </div>
            {product.recommendations.map((p) => (
              <Link
                to={`/product/${p}`}
                key={`product-${p}`}
                style={{ textDecoration: "none", color: "inherit" }}
                onClick={() => {
                  window.amplitude.getInstance().logEvent("PRODUCT_VIEW", {
                    uuid: p,
                    name: orderStore.products[p].name,
                    venue_uuid: venueStore.venue.id,
                    venue_name: venueStore.venue.name,
                  });
                }}
              >
                <div
                  className={
                    orderStore.order.products[p] && orderStore.order.products[p].quantity > 0
                      ? "product-fp-recommended-product-selected"
                      : "product-fp-recommended-product"
                  }
                >
                  <div className='product-fp-recommended-product-info'>
                    <div className='product-fp-recommended-product-name'>
                      {orderStore.order.products[p] && orderStore.order.products[p].quantity > 0
                        ? orderStore.order.products[p].quantity + "x " + orderStore.products[p].name
                        : orderStore.products[p].name}
                    </div>
                    <div className='product-fp-recommended-product-desc'>
                      <LinesEllipsis
                        text={orderStore.products[p].recipe || ''}
                        maxLine='2'
                        ellipsis='...'
                        trimRight
                        basedOn='words'
                      />
                    </div>
                    <div className='price' style={{ color: orderStore.products[p].highlight_color || "" }}>
                      {getPriceLabel(orderStore.products[p])}
                    </div>
                  </div>
                  {orderStore.products[p].image ? <img src={orderStore.products[p].image} height='75px' width='75px' /> : null}
                </div>
              </Link>
            ))}
          </div>
        ) : null}
        <div className='product-fp-inset' />
      </div>
      {payment || listEnabled ? (
        <div className='product-fp-footer'>
          <button className='product-fp-footer-button' onClick={addSelection}>
            <div className='product-fp-button-left'>{t("Menu.go")}</div>
            <div className='product-fp-button-right'>{formatCurrency(total(), venueStore.venue.currency)}</div>
          </button>
        </div>
      ) : null}
    </>
  );
};
